export default {
  APISTATUS: "APISTATUS",
  GET_VIDEO_DETAILS: "GET_VIDEO_DETAILS",
  FETCH_TRANSCRIPT: "FETCH_TRANSCRIPT",
  GET_TRANSCRIPT_LANGUAGES: "GET_TRANSCRIPT_LANGUAGES",
  GENERATE_TRANSCRIPT: "GENERATE_TRANSCRIPT",
  GENERATE_YT_TRANSCRIPT: "GENERATE_TRANSCRIPT",
  SAVE_TRANSCRIPT: "SAVE_TRANSCRIPT",
  GENERATE_TRANSLATION: "GENERATE_TRANSLATION",
  GET_TRANSLATION_LANGAUGES: "GET_TRANSLATION_LANGAUGES",
  SAVE_TRANSLATION: "SAVE_TRANSLATION",
  FETCH_TRANSLATION: "FETCH_TRANSLATION",
  GET_RECENT_LINKS: "GET_RECENT_LINKS",
};
  